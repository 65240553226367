import axios from 'axios';
import router from './router';

export let BaseUrl =
  process.env.NODE_ENV == 'development'
    ? 'https://api-staging.datasnipper.com/dashboard'
    : 'https://api.datasnipper.com/dashboard';

const Api = axios.create({
  baseURL: BaseUrl,
  timeout: 600000,
  headers: { 'Content-Type': 'application/json' },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true
});

Api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      router.push('/Login').catch((pushError) => {
        if (pushError.name !== 'NavigationDuplicated') {
          console.log(pushError);
        }
      });
    }

    return Promise.reject(err);
  }
);

export default Api;
