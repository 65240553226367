import 'regenerator-runtime/runtime';
import 'core-js/stable';
import Vue from 'vue';
import App from './App';
import router from './router';
import CoreUI from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import {
  BootstrapVue,
  IconsPlugin,
  BFormFile,
  FormFilePlugin,
  BootstrapVueIcons,
  TabsPlugin
} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueAppInsights from './application_insights';
import initializeMetrics from './metrics';
import { VuePlugin as VueReact } from 'vuera';

if (process.env.NODE_ENV != 'development') {
  Vue.use(VueAppInsights, {
    baseName: 'dashboard',
    id: '1c596d12-014f-42cb-a1f9-7a460e2a7207',
    router
  });
}

initializeMetrics();

Vue.config.performance = true;
Vue.prototype.$axios = axios;
Vue.use(CoreUI);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(FormFilePlugin);
Vue.use(BootstrapVueIcons);
Vue.use(TabsPlugin);
Vue.prototype.$log = console.log.bind(console);
Vue.use(VueReact);

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App,
    Datepicker,
    BFormFile
  }
});
