// Copyright from: https://github.com/latelierco/vue-application-insights/

import { ApplicationInsights, Util } from '@microsoft/applicationinsights-web';
import { logErrorsAndExceptions } from '../logger.js';

/**
 * Install function passed to Vue.use() show documentation on vue.js website.
 *
 * @param Vue
 * @param options
 */
function install(Vue, options) {
  const config = options.appInsightsConfig || {};
  config.instrumentationKey = config.instrumentationKey || options.id;

  if (options.appInsights) {
    Vue.appInsights = options.appInsights;
  } else {
    Vue.appInsights = new ApplicationInsights({ config });
    Vue.appInsights.loadAppInsights();
    if (typeof options.onAfterScriptLoaded === 'function') {
      options.onAfterScriptLoaded(Vue.appInsights);
    }
  }

  const router = options.router;

  // Watch route event if router option is defined.
  if (router) {
    if (options.trackInitialPageView !== false) {
      setupPageTracking(options, Vue);
    } else {
      router.onReady(() => setupPageTracking(options, Vue));
    }
  }

  Object.defineProperty(Vue.prototype, '$appInsights', {
    get: () => Vue.appInsights
  });

  Vue.config.errorHandler = function (err, vm, info) {
    logErrorsAndExceptions(info ?? 'global', err);
  };
}

/**
 * Track route changes as page views with AppInsights
 * @param options
 */
function setupPageTracking(options, Vue) {
  const router = options.router;

  const baseName = options.baseName || 'DataSnipper Dashboard';

  router.beforeEach((route, from, next) => {
    const name = baseName + ' / ' + route.name;
    Vue.appInsights.context.telemetryTrace.traceID = Util.generateW3CId();
    Vue.appInsights.context.telemetryTrace.name = route.name;
    Vue.appInsights.startTrackPage(name);
    next();
  });

  router.afterEach((route) => {
    const name = baseName + ' / ' + route.name;
    const url = location.protocol + '//' + location.host + route.fullPath;
    Vue.appInsights.stopTrackPage(name, url);
    Vue.appInsights.flush();
  });
}

// auto install for navigator
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install);
}

export default install;
