import Vue from 'vue';
import Router from 'vue-router';
import User from '../user.js';
import store from '../store.js';

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard = () => import('@/views/Dashboard');
const Login = () => import('@/views/Login');
const Register = () => import('@/views/Register');
const ForgotPassword = () => import('@/views/ForgotPassword');
const ResetPassword = () => import('@/views/ResetPassword');
const ConfirmEmail = () => import('@/views/ConfirmEmail');
const ActivateAccount = () => import('@/views/ActivateAccount');
const ForgotEmail = () => import('@/views/ForgotEmail');
const TemplatesManager = () => import('@/views/TemplatesManager');
const UpdateFormExtractionTemplate = () =>
  import('@/views/UpdateFormExtractionTemplate');
const UpdateDocumentMatchingTemplate = () =>
  import('@/views/UpdateDocumentMatchingTemplate');
const AddFormExtractionTemplate = () =>
  import('@/views/AddFormExtractionTemplate');
const AddDocumentMatchingTemplate = () =>
  import('@/views/AddDocumentMatchingTemplate');
const LicenseManagement = () => import('@/views/LicenseManagement.vue');
const ExcelOnline = () => import('@/views/ExcelOnline.vue');
const NotFound = () => import('@/views/NotFound.vue');
const UserManagement = () => import('@/views/UserManagement.vue');

Vue.use(Router);

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

router.afterEach((to) => {
  const hasGATrackingCode = !!process.env.VUE_APP_GA_TRACKING_ID;
  const isGAInitialized = window.gtag !== undefined;
  if (hasGATrackingCode && isGAInitialized) {
    window.gtag('event', 'page_view', {
      page_title: document.title,
      page_location: window.location.href.toLowerCase(),
      page_path: to.fullPath.toLowerCase()
    });
  }
});

function configRoutes() {
  return [
    {
      path: '/Login',
      name: 'Login',
      component: Login,
      beforeEnter: async function (to, from, next) {
        if (to.query.aud && to.query.token) {
          if (await User.adminLogin(to.query.aud, to.query.token)) {
            next('/Dashboard');
            return;
          }
        }
        next();
      }
    },
    {
      path: '/Register',
      name: 'Register',
      component: Register
    },
    {
      path: '/ForgotPassword',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/ResetPassword',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/ConfirmEmail',
      name: 'ConfirmEmail',
      component: ConfirmEmail
    },
    {
      path: '/ActivateAccount',
      name: 'ActivateAccount',
      component: ActivateAccount
    },
    {
      path: '/ForgotEmail',
      name: 'ForgotEmail',
      component: ForgotEmail
    },
    {
      path: '/',
      name: 'Home',
      redirect: '/Dashboard',
      component: TheContainer,
      beforeEnter: async function (to, from, next) {
        await User.loadStatus();
        await User.loadCompanies();
        await User.loadChargeBeeSubscription();
        next();
      },
      children: [
        {
          path: '/Dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/LicenseManagement',
          name: 'LicenseManagement',
          component: LicenseManagement,
          beforeEnter: (to, from, next) => {
            if (store.getters.isSelfServe) {
              next();
            } else {
              next('/404');
            }
          }
        },
        {
          path: '/ExcelOnline',
          name: 'ExcelOnline',
          component: ExcelOnline,
          beforeEnter: (to, from, next) => {
            if (
              store.getters.isOwner &&
              store.getters.isFeatureFlagEnabled('Datasnipper for Excel Online')
            ) {
              next();
            } else {
              next('/404');
            }
          }
        },
        {
          path: '/UserManagement',
          name: 'UserManagement',
          component: UserManagement,
          beforeEnter: (to, from, next) => {
            if (store.getters.isUmsEnabled) {
              next();
            } else {
              next('/404');
            }
          }
        },
        {
          path: '/404',
          name: 'NotFound',
          component: NotFound
        },
        {
          path: '/TemplatesManager',
          name: 'TemplatesManager',
          reload: true,
          component: TemplatesManager
        },
        {
          path: '/TemplatesManager/FormExtraction/UpdateTemplate',
          name: 'UpdateFormExtractionTemplate',
          component: UpdateFormExtractionTemplate
        },
        {
          path: '/TemplatesManager/DocumentMatching/UpdateTemplate',
          name: 'UpdateDocumentMatchingTemplate',
          component: UpdateDocumentMatchingTemplate
        },
        {
          path: '/TemplatesManager/FormExtraction/AddTemplate',
          name: 'AddFormExtractionTemplate',
          component: AddFormExtractionTemplate
        },
        {
          path: '/TemplatesManager/DocumentMatching/AddTemplate',
          name: 'AddDocumentMatchingTemplate',
          component: AddDocumentMatchingTemplate
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    }
  ];
}

export default router;
