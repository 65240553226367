import { v4 as uuidV4 } from 'uuid';
import Api from './api';
import { logErrorsAndExceptions } from './logger';

let sessionId = uuidV4();
let pendingMetrics = [];
const aggregationInterval = 1000 * 30;
let intervalId;

export const Measurements = {
  COMPARISON_ENABLED: 'COMPARISON_ENABLED',
  COMPARISON_DISABLED: 'COMPARISON_DISABLED',
  DATE_RANGE_APPLIED: 'DATE_RANGE_APPLIED',
  DATE_RANGE_CANCELED: 'DATE_RANGE_CANCELED'
};

export function addMetric(metric) {
  pendingMetrics.push({
    timestamp: Date.now(),
    ...metric
  });
}

export async function sendMetrics() {
  if (pendingMetrics.length == 0) {
    return;
  }

  const currentMetrics = pendingMetrics;
  pendingMetrics = [];

  try {
    await Api.post('/metrics/new', {
      sessionId,
      metrics: currentMetrics
    });
  } catch (error) {
    logErrorsAndExceptions('sendMetrics', error);
    pendingMetrics.push(...currentMetrics);
  }
}

export default function initializeMetrics() {
  // Clear any existing interval
  if (intervalId) {
    clearInterval(intervalId);
  }

  intervalId = setInterval(sendMetrics, aggregationInterval);
}
