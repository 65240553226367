import Vue from 'vue';

export const SeverityLevel = {
  Verbose: 0,
  Information: 1,
  Warning: 2,
  Error: 3,
  Critical: 4
};

export function logErrorsAndExceptions(
  location,
  error,
  severityLevel = SeverityLevel.Error
) {
  if (Vue.appInsights && Vue.appInsights.trackException) {
    error = error instanceof Error ? error : new Error(error);
    Vue.appInsights.trackException(
      { exception: error, severityLevel },
      { location }
    );
  } else {
    console.error(location, ':', error);
  }
}

export function logInfoAndDebug(name, message) {
  if (Vue.appInsights && Vue.appInsights.trackEvent) {
    Vue.appInsights.trackEvent({ name }, { message });
  } else {
    console.log(name, ':', message);
  }
}
