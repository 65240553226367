const trackCustomEvent = ({ category, action, label = null, value = null }) => {
  if (!window.gtag) return;
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  });
};

const trackUserScopedEvent = (property) => {
  if (!window.gtag) return;
  window.gtag('set', 'user_properties', property);
};

export const gaTrackingMixin = {
  methods: {
    setInnerUser() {
      trackUserScopedEvent({ type_of_user: 'inner' });
    },
    setExternalUser() {
      trackUserScopedEvent({ type_of_user: 'external' });
    },
    registerAdvancedReportDownload() {
      trackCustomEvent({
        category: 'advanced_report',
        action: 'advanced_report_download'
      });
    },
    registerColorPaletteChange(value) {
      trackCustomEvent({
        category: 'color_palette',
        action: 'color_palette_change',
        value
      });
    },
    registerDateRangeOpen() {
      trackCustomEvent({
        category: 'date_range',
        action: 'date_range_open'
      });
    },
    registerDateRangeClose() {
      trackCustomEvent({
        category: 'date_range',
        action: 'date_range_close'
      });
    },
    registerDateRangeApply() {
      trackCustomEvent({
        category: 'date_range',
        action: 'date_range_apply'
      });
    },
    registerDateRangeCancel() {
      trackCustomEvent({
        category: 'date_range',
        action: 'date_range_cancel'
      });
    },
    registerDateRangeCompare(value) {
      trackCustomEvent({
        category: 'date_range',
        action: 'date_range_compare',
        value
      });
    }
  }
};
