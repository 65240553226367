import { init, track, Identify, identify } from '@amplitude/analytics-browser';

const isProduction = process.env.NODE_ENV === 'production';
const amplitudeKey = 'd87107e79fadf966a76841a8e2254d50';

export function initializeAnalytics() {
  if (isProduction) {
    init(amplitudeKey, {
      defaultTracking: {
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
        pageViews: false,
        attribution: false
      }
    });
  }
}

export function identifyUser(userId: string) {
  if (isProduction) {
    // sends identify event
    identify(new Identify(), { user_id: userId });
  }
}

type TrackingEventId =
  | 'account_activated'
  | 'user_invite_clicked'
  | 'user_invite_confirmed'
  | 'user_invite_canceled'
  | 'download_clicked'
  | 'download_copied'
  | 'metrics_page_selected'
  | 'licenses_page_selected'
  | 'subscription_mgmt_initiated'
  | 'get_in_touch_clicked'
  | 'user_deletion_clicked'
  | 'user_deletion_confirmed'
  | 'user_deletion_canceled';

type DownloadActionSource = 'header' | 'in-page';

function trackEvent(eventId: TrackingEventId, properties = {}) {
  if (isProduction) {
    track(eventId, properties);
  }
}

export function trackAccountActivated(payload: { email: string }) {
  trackEvent('account_activated', payload);
}

export function trackUserInviteClicked() {
  trackEvent('user_invite_clicked');
}

export function trackUserInviteConfirmed(payload: {
  name: string;
  email: string;
  used_seats: number;
  free_seats: number;
}) {
  trackEvent('user_invite_confirmed', payload);
}

export function trackUserInviteCanceled() {
  trackEvent('user_invite_canceled');
}

export function trackDownloadClicked(payload: {
  location: DownloadActionSource;
  link: string;
}) {
  trackEvent('download_clicked', payload);
}

export function trackDownloadCopied(payload: {
  location: DownloadActionSource;
  link: string;
}) {
  trackEvent('download_copied', payload);
}

export function trackMetricsPageSelected() {
  trackEvent('metrics_page_selected');
}

export function trackLicensesPageSelected() {
  trackEvent('licenses_page_selected');
}

export function trackSubscriptionMgmtInitiated() {
  trackEvent('subscription_mgmt_initiated');
}

export function trackGetInTouchClicked() {
  trackEvent('get_in_touch_clicked');
}

export function trackUserDeletionClicked(payload: {
  name: string;
  email: string;
}) {
  trackEvent('user_deletion_clicked', payload);
}

export function trackUserDeletionConfirmed(payload: {
  name: string;
  email: string;
  used_seats: number;
  free_seats: number;
}) {
  trackEvent('user_deletion_confirmed', payload);
}

export function trackUserDeletionCanceled() {
  trackEvent('user_deletion_canceled');
}
